<template>
  <BudgetWizard step="1"/>
</template>

<script>
import BudgetWizard from './components/BudgetWizard.vue'

export default {
  name: 'App',
  components: {
    BudgetWizard
  }
}
</script>
