<template>
  <button 
    :class="{ 
      'bg-[#E42211]' : label == 'Next' && step != 4 && step != 5 
                      || label == 'Enquire about this quote'
                      || label == 'Not required' 
                      || label == 'Next' && step == 4 && !option 
                      || label == 'Next' && step == 4 && option && choices.dogleg > 0 
                      || label == 'Next' && step == 4 && option && choices.flightlandings > 0 
                      || label == 'Next' && step == 4 && option && choices.landings > 0
                      || label == 'Next' && step == 5 &&  choices.totalfloor > 0 && choices.clearspan > 0,
      'bg-[#CCCCCC]' : label == 'Back' && step == 1 
                      || label == 'Next' && step == 4 && option && choices.dogleg == 0 && choices.flightlandings == 0  && choices.landings == 0
                      || label == 'Next' && step == 5 &&  choices.totalfloor < 1
                      || label == 'Next' && step == 5 &&  choices.clearspan < 1,
      'bg-[#727272]' : label == 'Back', 
      'cursor-not-allowed' : label == 'Back' && step ==  1,
      'w-full mb-2 sm:mb-0 sm:w-28' : label == 'Next' || label == 'Back', 
      'w-full mb-2 sm:mb-0 sm:w-48' : label == 'Not required', 
      'w-full sm:w-52' : label == 'Enquire about this quote'
    }" 
    class="h-12 text-white"
    @click="$emit('action',label)" 
  >
    <div class="flex text-center">
      <div v-if="label == 'Not required'" class="bg-white w-10 h-10 inline-block ml-1 pt-2">
        <svg :class="{ 'text-[#ebebeb]' : option, 'text-black' : !option }" class="w-6 h-6" style="margin: 0 auto;" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
      </div>
      <div :class="{
        'w-full' : label != 'Not required',
        'pt-2 pl-6' : label == 'Not required'
      }">
        {{label}}
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    label: String,
    colour: String,
    step: Number,
    option: Boolean,
    choices: Object
  }
}
</script>
