<template>
  <div class="w-full max-w-[1270px]">
    <Stepper :step="step" :info="config.step" @setStep="setStep($event)" />
    <Step :step="step" :config="config" :choices="choices" :selected="selected" :results="results" @setChoice="setChoice($event)" @setStep="setStep($event)" />
    <div class="text-right w-full sm:w-auto sm:flex-nowrap flex flex-wrap float-right">
      <Button v-if="step == 4" class="sm:mr-3" @action="buttonClick($event)" :choices="choices" label="Not required" :option="config.staircase.required" />
      <Button @action="buttonClick($event)"  :choices="choices" :step="step" label="Back" />
      <Button v-if="step != 7" class="sm:ml-3" @action="buttonClick($event)" :choices="choices" :step="step" label="Next" :option="config.staircase.required" />
      <Button v-if="step == 7" class="sm:ml-3" @action="buttonClick($event)" :choices="choices" :step="step" label="Enquire about this quote" :option="config.staircase.required" />
    </div>
  </div>
</template>

<script>
import Stepper from './Stepper.vue'
import Step from './Step.vue'
import Button from './Button.vue'

export default {
  name: 'BudgetWizard',
  components: {
    Step,
    Button,
    Stepper
  },
  data: function () {
    return {
      step: 1,
      config: {
        step: [
          {
            title: "Development type",
            description: "Please use the boxes provided to select your proposed development configuration. Once chosen, click the 'next' button to move to next step.",
          },
          {
            title: "Finishes",
            description: "Your required finish will impact the floor specification. Please choose between a screed or chipboard finish, and hit 'next' when you're ready.",
          },
          {
            title: "Partitions",
            description: "Your partition option will impact the floor specification. Please let us know if it's lightweight or studwork, and hit 'next' when you're ready.",
          },
          {
            title: "Staircase required?",
            description: "We don't just do floors. We are experts in the manufacture of precast concrete staircase and landings. Please let us know if you require an add-on.",
          },
          {
            title: "Sizes",
            description: "The important part! Please let us know the total proposed floor size in m² along with the clear span in m. Once you're happy with the details, click 'next'.",
          },
          {
            title: "Do you have planning permission?",
            description: "Your one question away from a price! Please let us know if you already have planning permission for the development. This will streamline your quotation process and assist us in moving forward.",
          },
          {
            title: "Summary",
            description: "",
          },
        ],
        development: {
          domestic: 1.5,
          offices: 2.5,
          retail: 4.0
        },
        finishes: {
          screed: 1.8,
          chipboard: 0.2
        },
        partitions: {
          lightweight: 1.0,
          studwork: 0.5
        },
        staircase: {
          dogleg: 0,
          flightlandings: 0,
          landings: 0,
          required: true,
        },
        sizes: {
          totalfloor: null,
          clearspan: null
        },
        planning: {
          yes: true,
          no: false
        },
        costs: {
          dogleg: {
            cost: 2740.00
          },
          flightlandings: {
            cost: 3530.00
          },
          landings: {
            cost: 1713.00
          },
          truck: {
            cost: 707.00
          },
          install: {
            cost: 2291.00
          },
          BeamBlock155: {
            cost: 30.37
          },
          BeamBlock225: {
            cost: 37.51
          },
          WarmFloorPro155: {
            cost: 30.21
          },
          Hollowcore150: {
            cost: 48.67
          },
          Hollowcore200: {
            cost: 48.80        
          },
          Hollowcore250: {
            cost: 53.20
          }
        }
      },
      choices: {
        development: 1.5,
        finishes: 1.8,
        partitions: 1.0,
        dogleg: 0,
        flightlandings: 0,
        landings: 0,
        staircase: true,
        totalfloor: 0,
        clearspan: 0,
        planning: true
      },
      selected: [
        {
          key: "development",
          type: "Development type",
          selected: null
        },        
        {
          key: "finishes",
          type: "Finishes",
          selected: null
        },
        {
          key: "partitions",
          type: "Partitions",
          selected: null
        },        
        {
          key: "staircase",
          type: "Staircase required",
          selected: null
        },
        {
          key: "sizes",
          type: "Sizes",
          selected: null
        },
        {
          key: "planning",
          type: "Planning Permission",
          selected: null
        }
      ]
    };
  },
  computed: {
    wsTotal() {
      const finishes = this.choices.finishes;
      const partitions = this.choices.partitions;
      const imposed = this.choices.development;
      return (finishes + partitions + imposed);
    },
    wuTotal() {
      const finishes = this.choices.finishes * 1.4;
      const partitions = this.choices.partitions * 1.4;
      const imposed = this.choices.development * 1.6;
      return (finishes + partitions + imposed);
    },
    T155SAlt_Ms() {
      const calc = Math.pow((5.17*8000/(398*(1.87+this.wsTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    T155SAlt_Mu() {
      const calc = Math.pow((7.28*8000/(398*(1.87*1.4+this.wuTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    T225SAlt_Ms() {
      const calc = Math.pow((9.94*8000/(398*(2.25+this.wsTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    T225SAlt_Mu() {
      const calc = Math.pow((15.22*8000/(398*(2.25*1.4+this.wuTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    T155WFPSAlt_Ms() {
      const calc = Math.pow((5.17*8000/(508*(0.57+this.wsTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    T155WFPSAlt_Mu() {
      const calc = Math.pow((7.28*8000/(508*(0.57*1.4+this.wuTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    PS150H10s_Ms() {
      const calc = Math.pow((51.99*8000/(1200*(2.95+this.wsTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    PS150H10s_Mu() {
      const calc = Math.pow((85.92*8000/(1200*((2.95*1.4)+this.wuTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    PS20010s_Ms() {
      const calc = Math.pow((82.05*8000/(1200*(2.97+this.wsTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    PS20010s_Mu() {
      const calc = Math.pow((132.89*8000/(1200*((2.97*1.4)+this.wuTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    PS25010s_Ms() {
      const calc = Math.pow((113.01*8000/(1200*(3.46+this.wsTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    PS25010s_Mu() {
      const calc = Math.pow((176.61*8000/(1200*((3.46*1.4)+this.wuTotal))),0.5);
      return parseFloat(calc).toFixed(3);
    },
    results() {
      const BeamBlock155 =  (Math.min(this.T155SAlt_Ms,this.T155SAlt_Mu))-0.1;
      const BeamBlock225 = (Math.min(this.T225SAlt_Ms,this.T225SAlt_Mu))-0.1;
      const WarmFloorPro155 = (Math.min(this.T155WFPSAlt_Ms,this.T155WFPSAlt_Mu))-0.1;
      const Hollowcore150 = (Math.min(this.PS150H10s_Ms,this.PS150H10s_Mu))-0.1;
      const Hollowcore200 = (Math.min(this.PS20010s_Ms,this.PS20010s_Mu))-0.1;
      const Hollowcore250 = (Math.min(this.PS25010s_Ms,this.PS25010s_Mu))-0.1;

      const BeamBlock155_Trucks =  Math.ceil(this.choices.totalfloor/320);
      const BeamBlock225_Trucks = Math.ceil(this.choices.totalfloor/210);
      const WarmFloorPro155_Trucks = Math.ceil(this.choices.totalfloor/430);
      const Hollowcore150_Trucks = Math.ceil(this.choices.totalfloor/80);
      const Hollowcore200_Trucks = Math.ceil(this.choices.totalfloor/80);
      const Hollowcore250_Trucks = Math.ceil(this.choices.totalfloor/70);

      const BeamBlock155_Days =  Math.ceil(this.choices.totalfloor/200);
      const BeamBlock225_Days = Math.ceil(this.choices.totalfloor/180);
      const WarmFloorPro155_Days = Math.ceil(this.choices.totalfloor/200);
      const Hollowcore150_Days = Math.ceil(this.choices.totalfloor/250);
      const Hollowcore200_Days = Math.ceil(this.choices.totalfloor/250);
      const Hollowcore250_Days = Math.ceil(this.choices.totalfloor/250);

      const BeamBlock155_Supply_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * BeamBlock155_Trucks) + (this.choices.totalfloor * this.config.costs.BeamBlock155.cost);
      const BeamBlock225_Supply_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * BeamBlock225_Trucks) + (this.choices.totalfloor * this.config.costs.BeamBlock225.cost);
      const WarmFloorPro155_Supply_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * WarmFloorPro155_Trucks) + (this.choices.totalfloor * this.config.costs.WarmFloorPro155.cost);

      const BeamBlock155_Supply_Install_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * BeamBlock155_Trucks) + (this.choices.totalfloor * this.config.costs.BeamBlock155.cost) + (this.config.costs.install.cost * BeamBlock155_Days);
      const BeamBlock225_Supply_Install_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * BeamBlock225_Trucks) + (this.choices.totalfloor * this.config.costs.BeamBlock225.cost)+ (this.config.costs.install.cost * BeamBlock225_Days);
      const WarmFloorPro155_Supply_Install_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * WarmFloorPro155_Trucks) + (this.choices.totalfloor * this.config.costs.WarmFloorPro155.cost)+ (this.config.costs.install.cost * WarmFloorPro155_Days);
      const Hollowcore150_Supply_Install_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * Hollowcore150_Trucks) + (this.choices.totalfloor * this.config.costs.Hollowcore150.cost) + (this.config.costs.install.cost * Hollowcore150_Days);
      const Hollowcore200_Supply_Install_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * Hollowcore200_Trucks) + (this.choices.totalfloor * this.config.costs.Hollowcore200.cost)+ (this.config.costs.install.cost * Hollowcore200_Days);
      const Hollowcore250_Supply_Install_Total = (this.choices.dogleg > 0 ? this.config.costs.dogleg.cost * this.choices.dogleg : 0) + (this.choices.flightlandings ? this.config.costs.flightlandings.cost : 0) + (this.choices.landings ? this.config.costs.landings.cost : 0) + (this.config.costs.truck.cost * Hollowcore250_Trucks) + (this.choices.totalfloor * this.config.costs.Hollowcore250.cost)+ (this.config.costs.install.cost * Hollowcore250_Days);
      
      const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      });

      return {
        available: {
          BeamBlock155: this.choices.clearspan <= BeamBlock155 ? "Yes" : "No",
          BeamBlock225: this.choices.clearspan <= BeamBlock225 ? "Yes" : "No",
          WarmFloorPro155: this.choices.clearspan <= WarmFloorPro155 ? "Yes" : "No",
          Hollowcore150: this.choices.clearspan <= Hollowcore150 ? "Yes" : "No",
          Hollowcore200: this.choices.clearspan <= Hollowcore200 ? "Yes" : "No",
          Hollowcore250: this.choices.clearspan <= Hollowcore250 ? "Yes" : "No",
        },
        maxSpan: {
          BeamBlock155: BeamBlock155 + 'm',
          BeamBlock225: BeamBlock225 + 'm',
          WarmFloorPro155: WarmFloorPro155 + 'm',
          Hollowcore150: Hollowcore150 + 'm',
          Hollowcore200: Hollowcore200 + 'm',
          Hollowcore250: Hollowcore250 + 'm',
        },
        supplyOnly: {
          BeamBlock155_Supply_Total: formatter.format(BeamBlock155_Supply_Total),
          BeamBlock225_Supply_Total: formatter.format(BeamBlock225_Supply_Total),
          WarmFloorPro155_Supply_Total: formatter.format(WarmFloorPro155_Supply_Total),
          BeamBlock155_Supply_M2: formatter.format(Math.round(BeamBlock155_Supply_Total / this.choices.totalfloor * 100) / 100),
          BeamBlock225_Supply_M2: formatter.format(Math.round(BeamBlock225_Supply_Total / this.choices.totalfloor * 100) / 100),
          WarmFloorPro155_Supply_M2: formatter.format(Math.round(WarmFloorPro155_Supply_Total / this.choices.totalfloor * 100) / 100)
        },
        supplyInstall: {
          BeamBlock155_Supply_Install_Total: formatter.format(BeamBlock155_Supply_Install_Total),
          BeamBlock225_Supply_Install_Total: formatter.format(BeamBlock225_Supply_Install_Total),
          WarmFloorPro155_Supply_Install_Total: formatter.format(WarmFloorPro155_Supply_Install_Total),
          Hollowcore150_Supply_Install_Total: formatter.format(Hollowcore150_Supply_Install_Total),
          Hollowcore200_Supply_Install_Total: formatter.format(Hollowcore200_Supply_Install_Total),
          Hollowcore250_Supply_Install_Total: formatter.format(Hollowcore250_Supply_Install_Total),
          BeamBlock155_Supply_Install_M2: formatter.format(Math.round(BeamBlock155_Supply_Install_Total / this.choices.totalfloor * 100) / 100),
          BeamBlock225_Supply_Install_M2: formatter.format(Math.round(BeamBlock225_Supply_Install_Total / this.choices.totalfloor * 100) / 100),
          WarmFloorPro155_Supply_Install_M2: formatter.format(Math.round(WarmFloorPro155_Supply_Install_Total / this.choices.totalfloor * 100) / 100),
          Hollowcore150_Supply_Install_M2: formatter.format(Math.round(Hollowcore150_Supply_Install_Total / this.choices.totalfloor * 100) / 100),
          Hollowcore200_Supply_Install_M2:formatter.format( Math.round(Hollowcore200_Supply_Install_Total / this.choices.totalfloor * 100) / 100),
          Hollowcore250_Supply_Install_M2: formatter.format(Math.round(Hollowcore250_Supply_Install_Total / this.choices.totalfloor * 100) / 100)
        }
      };
    }
  },
  methods: {
    setChoice(data) {
      if (data.selected != undefined) {
        this.choices[data.type] = data.selected;
        console.log(data.type + " " + data.selected)
      }
      if (isNaN(data.selected)) {
        this.choices[data.type] = 0;
      }
    },
    setStep(event) {
      this.step = event;
    },
    buttonClick(value) {
      if (value == 'Next') {
        if (this.step != 4 && this.step != 5) {
          this.step = this.step + 1;
        } else if (this.step == 4) {
          if (this.choices.dogleg > 0 || this.choices.landings > 0 || this.choices.flightlandings > 0) {
            this.step = this.step + 1;
          } else if (this.config.staircase.required == false) {
            this.step = this.step + 1;        
          }
        } else if (this.step == 5) {
          if (this.choices.totalfloor > 0 && this.choices.clearspan > 0) {
            this.step = this.step + 1;   
          }
        }
        /* Summary of selected choices */
        if (this.step == 7) {
          this.selected.forEach((selection,key)=>{
            if (selection.key == "development") {
              let development = null;
              if (this.choices.development == 1.5) {
                development = "Domestic/residential";
              } else if (this.choices.development == 2.5) {
                development = "Offices";
              } else if (this.choices.development == 4) {
                development = "Retail";
              }
              this.selected[key].selected = development;
            } else if (selection.key == "finishes") {
              let finishes = null;
              if (this.choices.finishes == 1.8) {
                finishes = "Screed";
              } else if (this.choices.finishes == 0.2) {
                finishes = "Chipboard";
              }
              this.selected[key].selected = finishes;
            } else if (selection.key == "partitions") {
              let partitions = null;
              if (this.choices.partitions == 1.0) {
                partitions = "Lightweight Blockword";
              } else if (this.choices.partitions == 0.5) {
                partitions = "Studwork";
              }
              this.selected[key].selected = partitions;
            } else if (selection.key == "staircase") {
              let staircase = null;
              if (!this.choices.dogleg && !this.choices.flightlandings && !this.choices.landings) {
                staircase = "No";
              } else {
                if (this.choices.dogleg) {
                  staircase = "Dogleg: " + this.choices.dogleg;
                }
                if (this.choices.flightlandings) {
                  staircase = staircase + "<br />Flight & landings: " + this.choices.flightlandings;
                }
                if (this.choices.landings) {
                  staircase = staircase + "<br />Flight only: " + this.choices.landings;
                }
              }
              this.selected[key].selected = staircase;
            } else if (selection.key == "sizes") {
              let sizes = null;
              sizes = "Total floor area: " + this.choices.totalfloor + "m<sup>2</sup><br />Clear Span: " + this.choices.clearspan + "m";

              this.selected[key].selected = sizes;
            } else if (selection.key == "planning") {
              let planning = null;
              if (this.choices.planning) {
                planning = "Yes";
              } else {
                planning = "No";
              }

              this.selected[key].selected = planning;
            }
          });
        }
      } else if (value == 'Not required') {
        this.choices.dogleg = 0;
        this.choices.landings = 0;
        this.choices.flightlandings = 0;
        this.config.staircase.required = this.config.staircase.required ? false : true;
      } else if (value == 'Enquire about this quote') {
        console.log('modal here')
      } else if (this.step != 1) {
        this.step = this.step - 1;
      }
    }
  }
}
</script>
