<template>
  <div class="md:flex">
    <!-- Header -->
    <div class="w-full">
      <h1 class="contents text-[38px] font-semibold pl-5 md:pl-0 w-96 absolute heading h1 text-grey-dark font-weight: 500;">{{info[step-1].title}}<div class="absolute mt-1 block w-12 h-1.5 bg-[#E42211]"></div></h1>
    </div>
    <!-- Progress -->
    <div class="mt-8 md:mt-0 flex self-end h-8 sm:h-12">
      <div :class="{ 'bg-[#E42211]' : step == 1, 'bg-[#d0d0d0]' : step != 1 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(1)">
        1
      </div>
      <span class="h-1/2 border-b border-[#d0d0d0] w-[20px] md:w-[45px]"></span>
      <div :class="{ 'bg-[#E42211]' : step == 2, 'bg-[#d0d0d0]' : step != 2 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(2)">
        2
      </div>
      <span class="h-1/2 border-b border-[#d0d0d0] w-[20px] md:w-[45px]"></span>
      <div :class="{ 'bg-[#E42211]' : step == 3, 'bg-[#d0d0d0]' : step != 3 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(3)">
        3
      </div>
      <span class="h-1/2 border-b border-[#d0d0d0] w-[20px] md:w-[45px]"></span>
      <div :class="{ 'bg-[#E42211]' : step == 4, 'bg-[#d0d0d0]' : step != 4 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(4)">
        4
      </div>
      <span class="h-1/2 border-b border-[#d0d0d0] w-[20px] md:w-[45px]"></span>
      <div :class="{ 'bg-[#E42211]' : step == 5, 'bg-[#d0d0d0]' : step != 5 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(5)">
        5
      </div>
      <span class="h-1/2 border-b border-[#d0d0d0] w-[20px] md:w-[45px]"></span>
      <div :class="{ 'bg-[#E42211]' : step == 6, 'bg-[#d0d0d0]' : step != 6 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(6)">
        6
      </div>
      <span class="h-1/2 border-b border-[#d0d0d0] w-[20px] md:w-[45px]"></span>
      <div :class="{ 'bg-[#E42211]' : step == 7, 'bg-[#d0d0d0]' : step != 7 }" class="h-7 w-7 sm:w-12 sm:h-12 text-white text-lg md:text-2xl flex justify-center items-center cursor-pointer" @click="onChangeStep(7)">
        7
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgessStepper',
  props: {
    step: Number,
    info: Array
  },
  methods: {
    onChangeStep(step) {
      if (this.step > step) {
        this.$emit('setStep',step);
      }
    },
  }
}
</script>