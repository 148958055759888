<template>
  <div 
    @click="$emit('select',{'type': type, 'selected': value})"  
    :class="{ 
      'bg-[#ebebeb] pb-1 sm:pb-0' : selected != value || input, 
      'bg-[#E42211] pb-1 sm:pb-0 text-white' : selected == value && !input ,
      'cursor-pointer' : !input,
      'w-full h-auto sm:w-72 sm:h-72' : !stack,
      'md:w-[730px] w-full h-24 float-right' : stack
    }"
  >
    <div v-if="image" class="mb-2" :class="{'bg-cover' : !image.includes('svg'), 'bg-size-100 text-red-100' : image.includes('svg')}" :style="'height:160px; background-repeat: no-repeat; background-position: center; background-image: url('+image+');'"></div>
    <div v-if="!stack">
      <p class="text-center text-2xl">{{headline}}</p>
      <p class="text-center mb-3" v-html="option"></p>
      <div v-if="!input" class="w-10 h-10 bg-white pt-2" style="margin: 0 auto;">
        <svg :class="{ 'text-[#ebebeb]' : selected != value, 'text-black' : selected == value }" class="w-6 h-6" style="margin: 0 auto;" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
      </div>
      <div v-if="input" class="pt-2 text-center" style="margin: 0 auto;">
        <input @change="$emit('select',{'type': type, 'selected': parseInt($event.target.value)})" :value="value != 0 ? value : null" class="w-20 h-10 text-center" type="text" placeholder="(Qty)">
      </div>
    </div>
    <div v-if="stack" class="h-full d:block flex flex-wrap justify-between items-center ">
      <div>
        <p class="text-2xl sm:ml-10 inline">{{headline}}</p>
        <TooltipPopper v-if="help" :content="help" class="cursor-pointer">
            <svg class="w-6 h-6 ml-2 -mt-1 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </TooltipPopper>
      </div>
      <div class="flex items-center">
        <div v-if="input">
          <input @change="$emit('select',{'type': type, 'selected': parseInt($event.target.value)})" :value="value != 0 ? value : null" class="mr-3 p-2 leading-5" type="text">
        </div>
        <p class="text-center text-2xl mb-3 sm:mr-10" v-html="option"></p>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'CheckBox',
  props: {
    headline: String,
    option: String,
    value: Number,
    selected: Number,
    type: String,
    input: Boolean,
    stack: Boolean,
    image: String,
    help: String
  },
}
</script>
<style>
:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.bg-size-100 {
  background-size: 100px;
}
</style>
